   /**
    * Processes the text to replace reference patterns with incrementing reference links.
    * Example: [ref: SUMMARY_5162] -> [reference_1](/reference/SUMMARY/5162)
    * @param {string} text - The text containing reference patterns.
    * @returns {string} - The processed text with clickable reference links.
    */
   export const processReferences = (text) => {
    let refCount = 0;
    const refRegex = /\[ref:\s?(SUMMARY|DATA_CHUNK|ANSWER)_(\d+)\]/g;
    return text.replace(refRegex, (match, type, id) => {
      refCount += 1;
      return `[(${refCount})](/reference/${type}/${id})`;
    });
  };