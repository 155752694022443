import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@mui/material';

const DemoAccountModal = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Demo Account Notice</DialogTitle>
      <DialogContent>
        <Typography>
          You are currently using a DEMO account. This means:
          <ul>
            <li>All data is simulated for demonstration purposes</li>
            <li>No real patient information is being used</li>
            <li>Features and functionality may be limited</li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained">
          I Understand
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemoAccountModal;