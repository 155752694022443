// src/api/axiosConfig.js

import axios from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL || 'https://api.orin.health';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token && token !== 'null') {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    
    if (
      error.response?.status === 401 &&
      !originalRequest._retry &&
      originalRequest.url !== '/auth/refresh'
    ) {
      originalRequest._retry = true;
      
      try {
        const currentToken = localStorage.getItem('token');
        
        if (!currentToken || currentToken === 'null') {
          throw new Error('No token available for refresh');
        }
        
        const refreshInstance = axios.create({
          baseURL: BASE_URL,
          headers: {
            'Content-Type': 'application/json',
          },
        });
        
        const response = await refreshInstance.post('/auth/refresh', null, {
          headers: {
            Authorization: `Bearer ${currentToken}`,
          },
        });
        
        const { access_token } = response.data;
        localStorage.setItem('token', access_token);
        
        originalRequest.headers.Authorization = `Bearer ${access_token}`;
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        localStorage.removeItem('token');
        localStorage.removeItem('userEmail');
        window.location.href = '/';
        return Promise.reject(refreshError);
      }
    }
    return Promise.reject(error);
  }
);

export { axiosInstance, BASE_URL };
