// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { TutorialProvider } from './contexts/TutorialContext';
import { DemoProvider } from './contexts/DemoContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <DemoProvider>
      <TutorialProvider>
        <Router>
          <App />
        </Router>
      </TutorialProvider>
    </DemoProvider>
  </React.StrictMode>
);

reportWebVitals();
