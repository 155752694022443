import React, { useState, useRef, useEffect } from 'react';
import { Box, Paper, Typography, TextField, Button, IconButton } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import RefreshIcon from '@mui/icons-material/Refresh';
import useAuth from '../hooks/useAuth';

const OrinChat = () => {
  const { user } = useAuth();
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([
    { text: "What can I help you with today?", sender: 'assistant' }
  ]);
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;

    // Add user message to chat
    setChatHistory(prev => [...prev, { text: message, sender: 'user' }]);
    const userMessage = message; // Preserve the current message
    setMessage('');
    setIsLoading(true);

    try {
      // Prepare the payload
      const payload = {
        message: userMessage,
        is_end: false
      };

      // Start the streaming request
      const response = await fetch('https://chat.orin.health/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`, // Include the user's token
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }

      // Stream the response
      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let assistantMessage = '';
      let doneReading = false;

      while (!doneReading) {
        const { done, value } = await reader.read();
        doneReading = done;
        const chunk = decoder.decode(value || new Uint8Array(), { stream: !done });
        const lines = chunk.split('\n\n');

        for (const line of lines) {
          if (line.trim() === '') continue;
          if (line.startsWith('data: ')) {
            const data = line.replace('data: ', '');
            if (data.startsWith('Error:')) {
              throw new Error(data);
            }
            assistantMessage += data;
            // Update the assistant message in the chat history
            setChatHistory(prev => {
              const updated = [...prev];
              // Check if last message is from assistant
              if (updated[updated.length - 1]?.sender === 'assistant') {
                // Update the last assistant message
                updated[updated.length - 1] = { text: assistantMessage, sender: 'assistant' };
              } else {
                // Add new assistant message
                updated.push({ text: assistantMessage, sender: 'assistant' });
              }
              return updated;
            });
          }
        }
      }
    } catch (error) {
      console.error('Error sending message:', error);
      setChatHistory(prev => [...prev, { text: "Sorry, I couldn't process your request.", sender: 'assistant' }]);
    } finally {
      setIsLoading(false);
    }
  };

  const handleResetChat = async () => {
    try {
      await fetch('https://chat.orin.health/reset', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.access_token}`,
        },
      });
      // Clear the chat history on the frontend
      setChatHistory([
        { text: "What can I help you with today?", sender: 'assistant' }
      ]);
    } catch (error) {
      console.error('Error resetting chat:', error);
    }
  };

  return (
    <Box sx={{ mt: 4, height: '60vh', display: 'flex', flexDirection: 'column' }}>
      <Paper 
        elevation={3} 
        sx={{ 
          flex: 1, 
          mb: 2, 
          p: 2, 
          overflow: 'auto',
          bgcolor: '#f5f5f5'
        }}
      >
        {chatHistory.map((msg, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              justifyContent: msg.sender === 'user' ? 'flex-end' : 'flex-start',
              mb: 1,
            }}
          >
            <Paper
              sx={{
                p: 1,
                maxWidth: '70%',
                bgcolor: msg.sender === 'user' ? 'primary.main' : 'white',
                color: msg.sender === 'user' ? 'white' : 'text.primary',
              }}
            >
              <Typography variant="body1">{msg.text}</Typography>
            </Paper>
          </Box>
        ))}
        <div ref={messagesEndRef} />
      </Paper>
      <form onSubmit={handleSubmit} style={{ display: 'flex', gap: '8px' }}>
        <TextField
          fullWidth
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          placeholder="Type your message..."
          variant="outlined"
          size="small"
          disabled={isLoading}
        />
        <Button 
          type="submit" 
          variant="contained" 
          endIcon={<SendIcon />}
          disabled={isLoading}
        >
          {isLoading ? 'Sending...' : 'Send'}
        </Button>
        <IconButton
          color="primary"
          onClick={handleResetChat}
          disabled={isLoading}
          title="Reset Chat"
        >
          <RefreshIcon />
        </IconButton>
      </form>
    </Box>
  );
};

export default OrinChat; 