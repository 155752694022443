import { useState } from 'react';
import { axiosInstance } from '../api/axiosConfig';

const useAuth = () => {
  const [user, setUser] = useState(() => {
    const userId = localStorage.getItem('userId');
    const userEmail = localStorage.getItem('userEmail');
    const userDataToken = localStorage.getItem('userDataToken');
    const token = localStorage.getItem('token');

    return userId && userEmail && token && token !== 'null'
      ? { id: Number(userId), email: userEmail, user_data_token: userDataToken, access_token: token }
      : null;
  });

  const login = (userData) => {
    console.log('Logging in user:', userData);
    setUser(userData);
    localStorage.setItem('userId', userData.id);
    localStorage.setItem('userEmail', userData.email);
    localStorage.setItem('token', userData.access_token);
    if (userData.user_data_token) {
      localStorage.setItem('userDataToken', userData.user_data_token);
    }
    axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${userData.access_token}`;
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('userId');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('userDataToken');
    localStorage.removeItem('token');
    delete axiosInstance.defaults.headers.common['Authorization'];
  };

  return { user, login, logout };
};

export default useAuth; 