import React from 'react';
import { Popper, Paper, Typography, IconButton, Fade } from '@mui/material';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const StyledPopper = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip + 2,
  '& .MuiPaper-root': {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
    color: '#fff',
    borderRadius: '8px',
    padding: '12px 16px',
    maxWidth: '280px',
    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.15)',
    position: 'relative',
    '&[data-popper-placement*="bottom"] &::before': {
      content: '""',
      position: 'absolute',
      top: -8,
      left: 'calc(50% - 8px)',
      width: 16,
      height: 16,
      backgroundColor: 'inherit',
      transform: 'rotate(45deg)',
    },
    '&[data-popper-placement*="top"] &::before': {
      content: '""',
      position: 'absolute',
      bottom: -8,
      left: 'calc(50% - 8px)',
      width: 16,
      height: 16,
      backgroundColor: 'inherit',
      transform: 'rotate(45deg)',
    }
  }
}));

const HighlightOverlay = styled('div')({
  position: 'fixed',
  pointerEvents: 'none',
  zIndex: 1400,
  animation: 'pulse 2s infinite',
  '@keyframes pulse': {
    '0%': {
      boxShadow: '0 0 0 0 rgba(25, 118, 210, 0.4)'
    },
    '70%': {
      boxShadow: '0 0 0 10px rgba(25, 118, 210, 0)'
    },
    '100%': {
      boxShadow: '0 0 0 0 rgba(25, 118, 210, 0)'
    }
  }
});

const TutorialTooltip = ({ open, anchorEl, message, onClose, placement = "top" }) => {
  if (!anchorEl) return null;

  const rect = anchorEl.getBoundingClientRect();

  return (
    <>
      <HighlightOverlay
        style={{
          top: rect.top - 4,
          left: rect.left - 4,
          width: rect.width + 8,
          height: rect.height + 8,
          borderRadius: '4px'
        }}
      />
      <StyledPopper
        open={open}
        anchorEl={anchorEl}
        placement={placement}
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 8],
            },
          },
          {
            name: 'flip',
            options: {
              fallbackPlacements: ['bottom', 'right', 'left'],
            },
          },
        ]}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={200}>
            <Paper>
              <IconButton
                size="small"
                onClick={onClose}
                sx={{
                  position: 'absolute',
                  right: 4,
                  top: 4,
                  color: 'rgba(255,255,255,0.7)',
                  '&:hover': {
                    color: '#fff',
                    backgroundColor: 'rgba(255,255,255,0.1)'
                  },
                  fontSize: '1.2rem'
                }}
              >
                👍
              </IconButton>
              <Typography sx={{ pr: 4, fontSize: '0.9rem', lineHeight: 1.4 }}>
                {message}
              </Typography>
            </Paper>
          </Fade>
        )}
      </StyledPopper>
    </>
  );
};

export default TutorialTooltip;