import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Box, Typography, CircularProgress, Link as MUILink, Button, Grid, Chip, Divider } from '@mui/material';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { axiosInstance } from '../api/axiosConfig';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { processReferences } from '../utils/processReferences';

/**
 * Formats a date string into a more readable format.
 * @param {string} dateString - The date string to format.
 * @returns {string} - Formatted date string.
 */
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

/**
 * Renders detailed information for DataChunk type references.
 * @param {object} data - The data chunk information.
 * @returns {JSX.Element} - Rendered DataChunk view.
 */
const DataChunkView = ({ data, renderers }) => {
  let parsedData;
  try {
    parsedData = typeof data === 'string' ? JSON.parse(data) : data;
    
    // Add default empty arrays if properties don't exist
    parsedData.procedures = parsedData.procedures || [];
    parsedData.diagnoses = parsedData.diagnoses || [];
    
    if (parsedData.additional_details) {
      parsedData.additional_details = processReferences(parsedData.additional_details);
    }
  } catch (e) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">
          <ReactMarkdown components={renderers} remarkPlugins={[remarkGfm]}>
            {processReferences(data)}
          </ReactMarkdown>
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h6" gutterBottom>
        Medical Encounter on {formatDate(parsedData.service_from)}
      </Typography>
      
      <Grid container spacing={3}>
        {/* Procedures */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Procedures:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {parsedData.procedures.map((proc, idx) => (
                <Chip key={idx} label={proc} color="primary" variant="outlined" />
              ))}
            </Box>
          </Box>

          {/* Diagnoses */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Diagnoses:
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
              {parsedData.diagnoses.map((diag, idx) => (
                <Chip key={idx} label={diag} color="secondary" variant="outlined" />
              ))}
            </Box>
          </Box>
        </Grid>

        {/* Provider Information */}
        <Grid item xs={12} md={6}>
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Provider:
            </Typography>
            <Typography variant="body2">
              {parsedData.provider_name}
            </Typography>
            <Typography variant="body2">
              {parsedData.provider_address}
            </Typography>
          </Box>

          {/* Billing Information */}
          <Box sx={{ mb: 3 }}>
            <Typography variant="subtitle1" sx={{ mb: 1, fontWeight: 'bold' }}>
              Billing:
            </Typography>
            <Typography variant="body2">
              Total Bill: ${parsedData.total_bill}
            </Typography>
            <Typography variant="body2">
              Insurance Coverage: {parsedData.insurance_coverage}%
            </Typography>
          </Box>
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />
      
      {/* Additional Details - now with processed references */}
      <Typography variant="body1">
        <ReactMarkdown components={renderers} remarkPlugins={[remarkGfm]}>
          {parsedData.additional_details}
        </ReactMarkdown>
      </Typography>
    </Box>
  );
};

const ReferenceDetail = () => {
  const { type, id } = useParams();
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  /**
   * Fetches the reference data based on type and id.
   */
  useEffect(() => {
    const fetchReference = async () => {
      try {
        const response = await axiosInstance.get(`/references/${type}/${id}`);
        setData(response.data);
      } catch (err) {
        console.error('Error fetching reference data:', err);
        setError('Failed to load reference data.');
      } finally {
        setLoading(false);
      }
    };

    fetchReference();
  }, [type, id]);

  /**
   * Custom renderer for links in ReactMarkdown.
   * Handles internal reference links by navigating without reloading the page.
   */
  const renderers = {
    a: ({ href, children }) => {
      const referenceRegex = /^\/reference\/(SUMMARY|DATA_CHUNK|ANSWER)\/(\d+)$/;
      const match = href.match(referenceRegex);
      if (match) {
        const [, refType, refId] = match;
        console.log(`Navigating to type: ${refType}, id: ${refId}`);
        return (
          <MUILink
            href="#"
            onClick={(e) => {
              e.preventDefault();
              navigate(`/reference/${refType}/${refId}`);
            }}
            color="primary"
            underline="hover"
            sx={{ color: '#2196f3' }}
          >
            {children}
          </MUILink>
        );
      }
      return (
        <MUILink href={href} target="_blank" rel="noopener noreferrer">
          {children}
        </MUILink>
      );
    },
  };

  if (loading) {
    return (
      <Box sx={{ mt: 4, textAlign: 'center' }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ mt: 2 }}>
          Loading reference data...
        </Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ mt: 4 }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
        <Button 
          variant="contained" 
          sx={{ mt: 2 }} 
          onClick={() => navigate(-1)}
        >
          Go Back
        </Button>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Button
          startIcon={<ArrowBackIcon />}
          onClick={() => navigate(-1)}
          sx={{ mr: 2 }}
        >
          Back
        </Button>
        <Typography variant="h5">
          {type === 'ANSWER' ? 'Answer Detail' :
           type === 'SUMMARY' ? 'Summary Detail' :
           'Data Chunk Detail'}
        </Typography>
      </Box>
      <Box sx={{ bgcolor: '#f5f5f5', p: 2, borderRadius: 2 }}>
        {type === 'DATA_CHUNK' ? (
          <DataChunkView data={data.content} renderers={renderers} />
        ) : type === 'ANSWER' ? (
          <Box>
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Question:
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                {data.question}
              </Typography>
              <Typography variant="subtitle1" sx={{ fontWeight: 'bold' }}>
                Answer:
              </Typography>
              <ReactMarkdown 
                remarkPlugins={[remarkGfm]}
                components={renderers}
              >
                {processReferences(data.content)}
              </ReactMarkdown>
            </Box>
          </Box>
        ) : (
          <ReactMarkdown 
            children={processReferences(data.content)}
            remarkPlugins={[remarkGfm]}
            components={renderers}
          />
        )}
      </Box>
    </Box>
  );
};

export default ReferenceDetail;