import React, { createContext, useContext, useState, useCallback } from 'react';
import TutorialTooltip from '../components/TutorialTooltip';

const TutorialContext = createContext();

export const TutorialProvider = ({ children }) => {
  const [currentTooltip, setCurrentTooltip] = useState(null);
  const [completedSteps, setCompletedSteps] = useState(() => {
    try {
      return JSON.parse(localStorage.getItem('tutorialSteps')) || {};
    } catch {
      return {};
    }
  });

  const showTooltip = useCallback((step, anchorEl, message, placement = 'top') => {
    if (!anchorEl || completedSteps[step]) {
      console.log('Step already completed or invalid anchorEl:', step);
      return;
    }

    setCurrentTooltip({ step, anchorEl, message, placement });
  }, [completedSteps]);

  const closeTooltip = useCallback(() => {
    if (currentTooltip) {
      const newCompletedSteps = {
        ...completedSteps,
        [currentTooltip.step]: true
      };
      setCompletedSteps(newCompletedSteps);
      localStorage.setItem('tutorialSteps', JSON.stringify(newCompletedSteps));
      setCurrentTooltip(null);
    }
  }, [currentTooltip, completedSteps]);

  return (
    <TutorialContext.Provider value={{ showTooltip, closeTooltip, currentTooltip }}>
      {children}
      {currentTooltip && (
        <TutorialTooltip
          open={true}
          anchorEl={currentTooltip.anchorEl}
          message={currentTooltip.message}
          onClose={closeTooltip}
          placement={currentTooltip.placement}
        />
      )}
    </TutorialContext.Provider>
  );
};

export const useTutorial = () => {
  const context = useContext(TutorialContext);
  if (!context) {
    throw new Error('useTutorial must be used within a TutorialProvider');
  }
  return context;
};