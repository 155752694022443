// frontend/src/hooks/useWebSocket.js

import { useEffect, useRef, useCallback, useState } from 'react';
import { BASE_URL } from '../api/axiosConfig';
import { axiosInstance } from '../api/axiosConfig';

const useWebSocket = (userId, onMessage, token) => {
  const ws = useRef(null);
  const pingInterval = useRef(null);
  const reconnectAttempts = useRef(0);
  const maxReconnectAttempts = 5;
  const reconnectTimeoutRef = useRef(null);
  const isConnecting = useRef(false);
  const [status, setStatus] = useState({
    initialProcessingComplete: false,
    fullSummaryCreated: null,
    summarizationActive: false,
    processingError: null,
    nextAction: null
  });

  const sendMessage = useCallback((message) => {
    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.send(message);
    }
  }, []);

  const handleWebSocketMessage = useCallback((message) => {
    console.log('Received WebSocket message:', message);

    if (message.event === 'full_summary_complete' && message.success) {
      setStatus(prev => ({
        ...prev,
        fullSummaryCreated: true,
        summarizationActive: false
      }));
    } else if (message.event === 'status_update') {
      setStatus(prev => ({
        ...prev,
        initialProcessingComplete: message.initial_processing_complete,
        summarizationActive: message.summarization_active,
        fullSummaryCreated: message.full_summary_created,
        processingError: message.processing_error,
        nextAction: message.next_action,
        lastStatusUpdate: message.last_status_update ? new Date(message.last_status_update) : null
      }));
    }
    onMessage?.(message);
  }, [onMessage]);

  const executeNextAction = useCallback(async () => {
    if (!status.nextAction || !userId || !token || status.summarizationActive) return;

    try {
      switch (status.nextAction) {
        case 'create_full_summary':
          if (!status.summarizationActive) {
            await axiosInstance.post(`/summaries/${userId}/full`);
            setStatus(prev => ({ 
              ...prev, 
              summarizationActive: true,
              nextAction: 'wait_for_processing'
            }));
          }
          break;
        case 'wait_for_processing':
          break;
        case 'show_error':
          console.error('Processing error:', status.processingError);
          break;
      }
    } catch (error) {
      console.error('Error executing next action:', error);
      setStatus(prev => ({ 
        ...prev, 
        summarizationActive: false,
        processingError: error.message 
      }));
    }
  }, [status, userId, token]);

  useEffect(() => {
    console.log('Next Action Effect:', {
      nextAction: status.nextAction,
      summarizationActive: status.summarizationActive,
      questionGenerationInitiated: status.questionGenerationInitiated
    });
    
    if (!status.nextAction || status.summarizationActive || status.questionGenerationInitiated) {
      console.log('Skipping next action because:', {
        noNextAction: !status.nextAction,
        isSummarizationActive: status.summarizationActive,
        isQuestionGenerationInitiated: status.questionGenerationInitiated
      });
      return;
    }
    executeNextAction();
  }, [status.nextAction, status.summarizationActive, status.questionGenerationInitiated, executeNextAction]);

  // Poll for status updates
  useEffect(() => {
    if (!userId || !token) return;

    const getPollInterval = () => {
      return (status.summarizationActive || status.questionGenerationInitiated) 
        ? 2000  // 2 seconds during active processing
        : 5000; // 5 seconds during idle
    };

    const interval = setInterval(() => {
      sendMessage('get_status');
    }, getPollInterval());

    return () => clearInterval(interval);
  }, [
    userId, 
    token, 
    sendMessage, 
    status.summarizationActive, 
    status.questionGenerationInitiated
  ]);

  const connect = useCallback(() => {
    if (!userId || !token || isConnecting.current) return;

    isConnecting.current = true;

    if (ws.current?.readyState === WebSocket.OPEN) {
      ws.current.close();
    }

    const url = new URL(BASE_URL);
    const protocol = url.protocol === 'https:' ? 'wss:' : 'ws:';
    const wsUrl = `${protocol}//${url.host}/ws/status/${userId}?token=${encodeURIComponent(token)}`;

    ws.current = new WebSocket(wsUrl);

    ws.current.onopen = () => {
      console.log('WebSocket connected');
      isConnecting.current = false;
      reconnectAttempts.current = 0;

      if (pingInterval.current) {
        clearInterval(pingInterval.current);
      }

      pingInterval.current = setInterval(() => {
        if (ws.current?.readyState === WebSocket.OPEN) {
          ws.current.send('ping');
        }
      }, 10000);
    };

    ws.current.onmessage = (event) => {
      if (event.data === 'ping' || event.data === 'pong') {
        return;
      }

      if (!event.data) {
        console.warn('Received empty WebSocket message');
        return;
      }

      try {
        const data = JSON.parse(event.data);
        console.log('Received WebSocket message:', data);
        if (data && typeof data === 'object') {
          handleWebSocketMessage(data);
        } else {
          console.warn('Received non-object WebSocket message:', data);
        }
      } catch (error) {
        console.error('Error parsing WebSocket message:', error, 'Raw message:', event.data);
      }
    };

    ws.current.onerror = (error) => {
      console.error('WebSocket error:', error);
    };

    ws.current.onclose = (event) => {
      console.log('WebSocket disconnected:', event);
      isConnecting.current = false;
      clearInterval(pingInterval.current);

      if (reconnectAttempts.current < maxReconnectAttempts) {
        const timeout = Math.min(1000 * Math.pow(2, reconnectAttempts.current), 30000);
        reconnectAttempts.current++;
        console.log(`Reconnecting in ${timeout}ms (attempt ${reconnectAttempts.current})`);

        reconnectTimeoutRef.current = setTimeout(connect, timeout);
      }
    };
  }, [userId, token, handleWebSocketMessage]);

  useEffect(() => {
    connect();

    return () => {
      clearInterval(pingInterval.current);
      clearTimeout(reconnectTimeoutRef.current);
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [connect]);

  return {
    status,
    sendMessage,
    executeNextAction
  };
};

export default useWebSocket;
