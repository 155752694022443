import React, { createContext, useContext, useState, useCallback } from 'react';
import DemoAccountModal from '../components/DemoAccountModal';

const DemoContext = createContext();

export const DemoProvider = ({ children }) => {
  const [isDemo] = useState(() => process.env.REACT_APP_ORIN_DEMO === 'true');
  const [showDemoModal, setShowDemoModal] = useState(false);

  const handleCloseDemoModal = useCallback(() => {
    setShowDemoModal(false);
    localStorage.setItem('demoModalShown', 'true');
  }, []);

  const showDemo = useCallback(() => {
    if (isDemo && !localStorage.getItem('demoModalShown')) {
      setShowDemoModal(true);
    }
  }, [isDemo]);

  return (
    <DemoContext.Provider value={{ 
      isDemo, 
      showDemoModal, 
      handleCloseDemoModal,
      showDemo 
    }}>
      {children}
      {showDemoModal && (
        <DemoAccountModal 
          open={showDemoModal} 
          onClose={handleCloseDemoModal} 
        />
      )}
    </DemoContext.Provider>
  );
};

export const useDemo = () => {
  const context = useContext(DemoContext);
  if (!context) {
    throw new Error('useDemo must be used within a DemoProvider');
  }
  return context;
};