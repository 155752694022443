// frontend/src/App.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useNavigate, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Tabs,
  Tab,
  IconButton,
  Menu,
  MenuItem,
  Avatar,
  Container,
  Box,
  CssBaseline,
} from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import StatusPill from './components/StatusPill';
import MyHealthHistory from './components/MyHealthHistory';
import OrinChat from './components/OrinChat';
import AuthScreen from './components/AuthScreen';
import { axiosInstance } from './api/axiosConfig';
import useAuth from './hooks/useAuth';
import ProcessingCompletedModal from './components/ProcessingCompletedModal';
import logo from './assets/orin_logo.png';
import useWebSocket from './hooks/useWebSocket';
import ReferenceDetail from './components/ReferenceDetail';
import { DemoProvider, useDemo } from './contexts/DemoContext';
import DemoAccountModal from './components/DemoAccountModal';
import { useTutorial } from './contexts/TutorialContext';

const ProtectedHealthHistory = () => {
  const { status } = useWebSocket();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('ProtectedHealthHistory status:', status);
    if (status.fullSummaryCreated === false) {
      navigate('/chat');
    }
  }, [status.fullSummaryCreated, navigate]);

  return <MyHealthHistory />;
};

function App() {
  const { user, login, logout } = useAuth();
  const { isDemo, showDemoModal, handleCloseDemoModal, showDemo } = useDemo();
  const navigate = useNavigate();
  const location = useLocation();
  const { showTooltip } = useTutorial();


  const [tabIndex, setTabIndex] = useState(0); // For Tabs
  const [anchorEl, setAnchorEl] = useState(null); // For Menu
  const openMenu = Boolean(anchorEl);

  const [processingStatus, setProcessingStatus] = useState({
    initial_processing_complete: false,
    full_summary_created: false,
    summarization_active: false,
    processing_error: null,
  });

  const [showModal, setShowModal] = useState(false);
  const [highlightTab, setHighlightTab] = useState(false);

  const tooltipElementsRef = useRef({
    statusPill: null,
    orinChatTab: null,
    healthHistoryTab: null
  });

  const handleWebSocketMessage = useCallback((message) => {
    if (message.event === 'initial_processing_complete') {
      setProcessingStatus(prev => ({
        ...prev,
        initial_processing_complete: message.success,
        processing_error: message.success ? null : 'Processing failed'
      }));
      if (message.success) {
        setProcessingStatus(prev => ({
          ...prev,
          summarization_active: true
        }));
      }
    } else if (message.event === 'full_summary_complete') {
      setProcessingStatus(prev => ({
        ...prev,
        full_summary_created: true,
        summarization_active: false
      }));
    }
  }, []);

  const handleProceed = useCallback(() => {
    setShowModal(false);
    setTabIndex(0);
    navigate('/chat');
  }, [navigate]);

  const { status } = useWebSocket(user?.id, handleWebSocketMessage, user?.access_token);

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
    setHighlightTab(false);
    switch (newValue) {
      case 0:
        navigate('/chat');
        break;
      case 1:
        navigate('/my-health-history');
        break;
      default:
        break;
    }
  };

  const renderNavigation = () => {
    const currentPath = location.pathname;
    
    let activeTab = 0;
    if (currentPath.startsWith('/my-health-history')) {
      activeTab = 1;
    }

    return (
      <AppBar position="static">
        <Toolbar>
          <img src={logo} alt="Orin Logo" style={{ height: '40px', marginRight: '20px' }} />
          <Tabs 
            value={activeTab} 
            onChange={handleTabChange}
            sx={{ flexGrow: 1 }}
            textColor="inherit"
            indicatorColor="secondary"
          >
            <Tab 
              label="Orin Chat" 
              sx={{ 
                opacity: 1,
                fontWeight: activeTab === 0 ? 700 : 400,
                transition: 'font-weight 0.2s ease'
              }}
            />
            {status.fullSummaryCreated && (
              <Tab 
                label="Health Summary" 
                sx={{ 
                  opacity: 1,
                  fontWeight: activeTab === 1 ? 700 : 400,
                  transition: 'font-weight 0.2s ease'
                }}
              />
            )}
          </Tabs>
          <StatusPill status={status} />
          <IconButton
            size="large"
            edge="end"
            color="inherit"
            onClick={(e) => setAnchorEl(e.currentTarget)}
            sx={{ ml: 2 }}
          >
            <Avatar>{user?.email?.charAt(0).toUpperCase()}</Avatar>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={() => {
              setAnchorEl(null);
              logout();
            }}>
              Logout
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    );
  };

  // Customize the theme (optional)
  const theme = createTheme({
    palette: {
      primary: {
        main: '#1976d2',
      },
    },
  });

  useEffect(() => {
    if (user && isDemo) {
      showDemo();
    }
  }, [user, isDemo, showDemo]);

  useEffect(() => {
    if (user) {
      // Only initialize if tutorial steps haven't been completed
      const completedSteps = JSON.parse(localStorage.getItem('tutorialSteps') || '{}');
      if (Object.keys(completedSteps).length > 0) {
        return; // Skip if any steps are completed
      }

      const initializeTooltips = () => {
        const statusPillElement = document.querySelector('[data-testid="status-pill"] .MuiChip-root');
        const tabElements = Array.from(document.querySelectorAll('[role="tab"]'));
        const orinChatTab = tabElements[0];
        const healthHistoryTab = tabElements[1];

        if (statusPillElement) {
          setTimeout(() => {
            if (!completedSteps['status-pill']) {
              showTooltip('status-pill', statusPillElement,
                'Track your data processing status here', 'bottom');
            }
          }, 1000);
        }

        if (orinChatTab) {
          setTimeout(() => {
            if (!completedSteps['orin-chat']) {
              showTooltip('orin-chat', orinChatTab,
                'Start here to chat with Orin about your health', 'bottom');
            }
          }, 2000);
        }

        if (status.fullSummaryCreated && healthHistoryTab && !completedSteps['health-history']) {
          setTimeout(() => {
            showTooltip('health-history', healthHistoryTab,
              'View and download your health summary', 'bottom');
          }, 3000);
        }
      };

      // Initial delay to ensure elements are rendered
      const timeoutId = setTimeout(initializeTooltips, 500);
      return () => clearTimeout(timeoutId);
    }
  }, [user, status.fullSummaryCreated, showTooltip]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {user ? (
        <>
          {renderNavigation()}
          <Container>
            <Routes>
              <Route path="/chat" element={<OrinChat />} />
              <Route 
                path="/my-health-history" 
                element={
                  <React.Suspense fallback={<div>Loading...</div>}>
                    <ProtectedHealthHistory />
                  </React.Suspense>
                } 
              />
              <Route path="/reference/:type/:id" element={<ReferenceDetail />} />
              <Route path="/" element={<Navigate to="/chat" replace />} />
            </Routes>
          </Container>
          <DemoAccountModal 
            open={showDemoModal}
            onClose={handleCloseDemoModal}
          />
        </>
      ) : (
        <AuthScreen onLogin={login} />
      )}
    </ThemeProvider>
  );
}

export default App;
